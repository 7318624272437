<template>
<l-layout class="l-quick-bi-design"  :leftMove="false" :rightMove="false" :left="264" :right="344">
    <template #left >
        <l-panel style="padding-right:0;" >
            <div style="margin-bottom: 8px;" v-for="(myComponent, index) in componentList" :key="index">
                <div class="myComponent-title">{{$t(myComponent.title)}}</div>
                <div class="myComponent-item"
                    @click="addComponent(item)"
                    v-for="(item) in myComponent.list"
                    :key="item.type">
                    <div class="myComponent-item-body" >
                        <i class="myComponent-item-icon" :class="item.icon"></i>
                        <span>{{$t(item.title || item.label)}}</span>
                    </div>
                </div>
            </div>
        </l-panel>
    </template>
    <template #right >
        <l-panel style="padding-left:0;" >
            <div class="l-auto-window" >
                <el-tabs v-model="configActiveName" :stretch="true" >
                    <el-tab-pane :label="$t('基础设置')" name="info">
                        <component-config :data="activeData"></component-config>
                    </el-tab-pane>
                    <el-tab-pane v-if="activeData && !noDataConfigComponents.includes(activeData.type)" :label="$t('数据设置')" name="data">
                        <data-Config :data="activeData"></data-Config>
                    </el-tab-pane>
                    <el-tab-pane :label="$t('参数设置')" name="size">
                        <size-config :data="activeData"></size-config>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </l-panel>
    </template>

    <l-panel class="l-quick-bi-design__main" style="padding-left:0;padding-right:0;" >
        <template #title>
            <el-button @click="handleClear" plain size="mini" type="danger" icon="el-icon-delete">清空</el-button>
            <el-button @click="handleViewer" plain size="mini" type="primary" icon="el-icon-video-play">预览</el-button>
        </template>

        <div class="l-rblock l-quick-bi-design__main-content" :style="{background: layout.length == 0 ? `url(${imgUrl}) no-repeat 50%`: ''}" >
            <grid-layout :layout.sync="layout" :row-height="64">
                <grid-item v-for="item in layout" :x="item.x" :y="item.y" :w="item.w" :h="item.h"
                    :i="item.i" :key="item.i" :maxH="item.maxH" :minH="item.minH" :maxW="item.maxW" :minW="item.minW"
                    :class="{'active-item': item.i===activeId}" @resized="resizedComponent"
                    @click.native="clickComponent(item)">
                    <bi-item :data="item" :isShow="isShow" ></bi-item>
                    <div class="mask"></div>
                    <el-button :title="$t('复制')"
                                v-if="item.i === activeId"
                                @click.stop="addComponent(item)"
                                class="action-clone"
                                circle
                                plain
                                size="mini"
                                type="primary">
                        <i class="el-icon-copy-document"></i>
                    </el-button>
                    <el-button :title="$t('删除')"
                                v-if="item.i === activeId"
                                @click.stop="removeComponent(item.i)"
                                class="action-delete"
                                circle
                                plain
                                size="mini"
                                type="danger">
                        <i class="el-icon-delete"></i>
                    </el-button>
                </grid-item>
            </grid-layout>
        </div>
    </l-panel>

    <l-fullscreen-dialog
        :title="$t('设计预览')"
        :visible.sync="viewerVisible"
        :showOk="false"
        @opened="handleOpenedViewer"
        @closed="handleClosedViewer"
        >
        <l-quick-bi-viewer ref="quickViewer"  :isPreview="true" ></l-quick-bi-viewer>
    </l-fullscreen-dialog>

</l-layout>
</template>
<script>
import BiItem from './ibItem.vue'

import ComponentConfig from '../../config/componentConfig.vue'
import SizeConfig from '../../config/sizeConfig.vue'
import DataConfig from '../../config/dataConfig.vue'
export default {
    components:{
        BiItem,
        ComponentConfig,
        SizeConfig,
        DataConfig
    },
    name:'l-quick-bi-design',
    provide () {
        return {
            quickBiDesign: this
        };
    },
    props:{
        componentList:{
            type:Array,
            default:()=>[]
        },
        noDataConfigComponents:{
            type:Array,
            default:()=>[]
        },
        isShow:Boolean
    },
    data(){
        return {
            imgUrl:require("../../img/widget-empty.png"),
            viewerVisible:false,
            configActiveName:'info',
            layout: [],
            activeData:null,
            custmerformSchemes:{}

        }
    },
    computed:{
        activeId(){
            if(this.activeData){
                return this.activeData.i
            }
            else{
                return ''
            }
        }
    },
    mounted () {
    },
    methods:{
        handleClear(){
            this.layout = []
            this.activeData = null
        },
        handleViewer(){
            this.viewerVisible = true
        },
        handleOpenedViewer(){
            this.$refs.quickViewer.set(this.layout)
        },
        handleClosedViewer(){
            this.$refs.quickViewer.reset()
        },


        addComponent(item) {
            const clone = this.$deepClone(item)
            const x = 0 
            let y = 0
            const i = Date.now() + '_' + Math.ceil(Math.random() * 99999)
            if (this.layout.length > 0) {
                const lastItem = this.layout[this.layout.length - 1]
                y = lastItem.y + lastItem.h
            }

            const row = { ...clone, i, x, y }
            this.layout.push(row)

            this.activeData = row
        },
        removeComponent(i){
            this.layout = this.layout.filter(item => item.i !== i)
            this.activeData = null
        },
        clickComponent(item){
            this.activeData = item
        },
        resizedComponent(){

        },

        get(){
            return this.$deepClone(this.layout)
        },
        set(data){
            this.layout = data
        }
    }
}
</script>

<style lang="less">
@import './index.less';
</style>