<template>
    <div class="component-config">
        <el-form
                v-if="this.data && Object.keys(this.data).length > 0"
                label-width="88px"
                label-position="left"
                size="mini">
                <el-form-item label="宽度">
                  <el-input-number v-model="data.w" :max="data.maxW" :min="data.minW" controls-position="right" ></el-input-number>
                </el-form-item>
                <el-form-item label="高度">
                  <el-input-number v-model="data.h" :max="data.maxH" :min="data.minH" controls-position="right" ></el-input-number>
                </el-form-item>
        </el-form>
        <div v-else style="width: 100%;
            text-align: center;
            margin-top: 100%;color:#909399;" >
            请选中组件进行设置！
        </div>
    </div>
</template>

<script>
export default {
    name: 'size-config',
    props: ['data'],
    components:{
    },
    computed: {
    },
    data () {
        return {
        }
    },
    methods: {
        
    }
}
</script>
