<template>
    <div class="component-config">
        <el-form
                v-if="this.data && Object.keys(this.data).length > 0"
                label-width="88px"
                label-position="left"
                size="mini">
                <component :is="componentName"
                            :data="data"></component>
            <!--<el-form-item label="数据源">
                <l-select  
                        v-model="data.dataCode"
                        placeholder="请选择数据源" 
                        size="mini" 
                        :options="dataSource" 
                        labelKey="f_Name" 
                        valueKey="f_Code"
                        filterable

                        @change="handleDataSourceChange"
                    >
                </l-select>
            </el-form-item>
            <el-form-item label="显示值" v-if="['databoard'].includes(data.type)" >
                <l-select  
                        v-model="data.dataValueKey"
                        placeholder="请选择值" 
                        size="mini" 
                        :options="myColNameList"

                        filterable
                    >
                </l-select>
            </el-form-item>
            <template v-if="['infolist'].includes(data.type)" >
                <el-form-item label="标题字段" >
                    <l-select  
                            v-model="data.dataTextKey"
                            placeholder="请选择" 
                            size="mini" 
                            :options="myColNameList"

                            filterable
                        >
                    </l-select>
                </el-form-item>
                <el-form-item label="时间字段" >
                    <l-select  
                            v-model="data.dataTimeKey"
                            placeholder="请选择" 
                            size="mini" 
                            :options="myColNameList"

                            filterable
                        >
                    </l-select>
                </el-form-item>
            </template>-->
            
        </el-form>
        <div v-else style="width: 100%;
            text-align: center;
            margin-top: 100%;color:#909399;" >
            请选中组件进行设置！
        </div>
    </div>
</template>

<script>
export default {
    name: 'size-config',
    props: ['data'],
    components:{
    },
    computed: {
        componentName(){
            return `${this.data.type}-data-config`
        }
        /*dataSource(){
            if(this.lr_dataSource){
                return this.lr_dataSource
            }
            else{
                return []
            }
        },
        myColNameList(){
            if(this.lr_dataSourceCol && this.data.dataCode){
                const colNameList = this.lr_dataSourceCol[this.data.dataCode] || []
                return colNameList.map(t=>{return {value:t,label:t}})
            }
            else{
                return []
            }
            
        },*/
    },
    data () {
        return {
        }
    },
    methods: {
        /*handleDataSourceChange(){
            if(!this.$validatenull(this.data.dataCode) && this.lr_loadDataSourceColNames){
                this.lr_loadDataSourceColNames(this.data.dataCode)
            }
        },*/
    }
}
</script>
