<template>
    <div class="component-config">
        <el-form
                v-if="this.data && Object.keys(this.data).length > 0"
                label-width="88px"
                label-position="left"
                size="mini">
                <el-form-item label="标题">
                    <el-input v-model="data.label" >
                    </el-input>
                </el-form-item>
                <component :is="componentName"
                            :data="data"></component>
        </el-form>
        <div v-else style="width: 100%;
            text-align: center;
            margin-top: 100%;color:#909399;" >
            请选中组件进行设置！
        </div>
    </div>
</template>

<script>
export default {
    name: 'component-config',
    props: ['data'],
    computed: {
        componentName(){
            return `${this.data.type}-config`
        }
    },
    data () {
        return {
        }
    },
    methods: {
        
    }
}
</script>
