<template>
<div class="l-rblock l-quick-bi-viewer" >
    <grid-layout :layout="layout" :row-height="64" :is-draggable="false"
        :is-resizable="false">
        <grid-item v-for="item in layout" :x="item.x" :y="item.y" :w="item.w" :h="item.h"
            :i="item.i" :key="item.i" >
            <bi-item :data="item" :isPreview="isPreview" ></bi-item>
        </grid-item>
    </grid-layout>
</div>
</template>
<script>
import BiItem from '../../quickBIDesign/_src/ibItem.vue'
export default {
    components:{
        BiItem
    },
    name:'l-quick-bi-viewer',
    props:{
        isPreview:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return {
            layout:[]
        }
    },
    computed:{
    },
    mounted () {
    },
    methods:{
        set(data){
            this.layout = this.$deepClone(data)
        },
        reset(){
            this.layout.length = 0
        }
    }
}
</script>

<style lang="less">
@import './index.less';
</style>