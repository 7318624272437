<template>
    <component :is="componentName" :data="data" :isPreview="isPreview" :isShow="isShow" >
    </component>
</template>
<script>
export default {
    name: 'bi-item',
    props: {
        data: {
            type: Object,
            default: () => {
                return {}
            }
        },
        isPreview:{
            type:Boolean,
            default:false
        },
        isShow:{
            type:Boolean,
            default:false
        }
    },
    computed:{
        componentName(){
            return `${this.data.type}-data-view`
        }
    }
}
</script>